/* https://github.com/ConnorAtherton/loaders.css */
/* Lines Loading Animation */
@-webkit-keyframes lineScale {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}
@keyframes lineScale {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

.lineScale > div:nth-child(1) {
  -webkit-animation: lineScale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: lineScale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.lineScale > div:nth-child(2) {
  -webkit-animation: lineScale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: lineScale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.lineScale > div:nth-child(3) {
  -webkit-animation: lineScale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: lineScale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.lineScale > div:nth-child(4) {
  -webkit-animation: lineScale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: lineScale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.lineScale > div:nth-child(5) {
  -webkit-animation: lineScale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: lineScale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.lineScale > div {
  background-color: #1890ff;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}
