.container {
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #001529;
  .header {
    color: #fff;
    text-align: center;
    margin: 16px 0px;
    font-size: 20px;
  }
  .logo {
    width: 50px;
    height: 50px;
  }
  .logoSmall {
    width: 32px;
    height: 32px;
  }
}

.trigger {
  text-align: center;
  color: #fff;
  font-size: 16px;
  transition: color 0.3s;
  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
}
